import React from "react";
import CategoryHamburgerMenuJA from "./CategoryHamburgerMenuJA";
import CategoryHamburgerMenuEN from "./CategoryHamburgerMenuEN";
import {LocaleContext} from "../layout";


const CategoryHamburgerMenu = () => {
    const {locale} = React.useContext(LocaleContext);
    return (
        <>
            {locale === 'ja' ? <CategoryHamburgerMenuJA/> : <CategoryHamburgerMenuEN/>}
        </>
    );
};

export default CategoryHamburgerMenu;