import React from "react";
import {LocaleContext} from "../layout";
import CategoryNavEN from "./CategoryNavEN";
import CategoryNavJA from "./CategoryNavJA";


const CategoryNav = () => {
    const {locale} = React.useContext(LocaleContext);
    return (
        <>
            {locale === 'ja' ? <CategoryNavJA/> : <CategoryNavEN/>}
        </>
    );
};

export default CategoryNav;