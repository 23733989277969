import React, {useState} from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";

const CategoryHamburgerMenu = () => {
const {
thermometers,
digitalThermometers,
dataloggersTemperature,
analogThermometers,
thermographs,
remoteSensingDialThermometers,
bimetalThermometers,
standardGlassThermometers,
glassThermometers,
thermocouples,
resistanceTemperatureDetectors,
thermohygrometers,
digitalThermohygrometers,
dataloggersTemperatureHumidity,
transmitters,
dialThermohygrometers,
thermohygrographs,
psychrometers,
anemometers,
heatStressMonitors,
infraredThermometers,
barometers,
digitalBarometer,
dialBarometers,
barograph,
recorders,
indicators,
weatherInstruments,
windSpeedDirectionIndicator,
anemometersWeatherInstruments,
rainGauge,
instrumentScreens,
moistureMeters,
soilPhMetersSaltMetersCo2MetersOtheres,
refractometers,
phMeters,
timers,
timersSecondLayer,
stopwatches,
sandglasses,
optionalProbes,
forSkL751,
forSkL753,
forSkL754,
forSkL200TIISeries,
forSkL200th2aSeries,
forSkL400tSk1110Sk1120Sk7000PRTII,
forSk1260,
forSk1250MCIIISk1250MCIIIa,
forSk270wpSeries,
forSk250wp2Series,
forSk100wp,
forSk810pt,
forSk110trhB,
forSkRhcSeries,
forSkM460TSkM350T,
forSk610ph2,
forSk660phSeries,
forSk620ph2Sk650ph,
forSk620ph,
chartsAndPens,
forSigma2Thermohygrographs,
forAurora903Thermohygrograph,
forSigma2Thermograph,
forSigma2Hygrograph,
forSigma2Barograph,
forSigma2RemoteThermographs,
forMiniCubeThermohygrograph,
forMiniStarThermohygrograph,
forMiniAlphaThermohygrograph,
forEventRecorder,
forLmmcTemperatureRecorder,
forVariousRecorders,
cartridgePens,
otherAccessories,
discontinuedProducts,
} = useTranslationsCat();

const [checked, setChecked] = useState(false)
const handleClick = () => setChecked(!checked)

return(
<ul className="ac-text level-2">
<li className={'ac-product'}>
<input type="checkbox" id="thermometers-censor" name="thermometers-censor" className="ac-input"/>
<label htmlFor="thermometers-censor" className="ac-label" >{thermometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/digital-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/dataloggers-temperature/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dataloggersTemperature}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/analog-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{analogThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/thermographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/remote-sensing-dial-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{remoteSensingDialThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bimetal-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bimetalThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/standard-glass-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{standardGlassThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/glass-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{glassThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/thermocouples/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermocouples}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/resistance-temperature-detectors/"  className="init-right line bold-600" onClick={()=>handleClick()}>{resistanceTemperatureDetectors}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="thermohygrometers-censor" name="thermohygrometers-censor" className="ac-input"/>
<label htmlFor="thermohygrometers-censor" className="ac-label" >{thermohygrometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/digital-thermohygrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalThermohygrometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/dataloggers-temperature-Humidity/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dataloggersTemperatureHumidity}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/transmitters/"  className="init-right line bold-600" onClick={()=>handleClick()}>{transmitters}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/dial-thermohygrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dialThermohygrometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/thermohygrographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermohygrographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/psychrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{psychrometers}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/anemometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{anemometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/heat-stress-monitors/" className="init-right line bold-600" onClick={()=>handleClick()}>{heatStressMonitors}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/infrared-thermometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{infraredThermometers}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="barometers-censor" name="barometers-censor" className="ac-input"/>
<label htmlFor="barometers-censor" className="ac-label" >{barometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/digital-barometer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalBarometer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/dial-barometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dialBarometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/barograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{barograph}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/recorders/" className="init-right line bold-600" onClick={()=>handleClick()}>{recorders}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/indicators/" className="init-right line bold-600" onClick={()=>handleClick()}>{indicators}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="weather-instruments-censor" name="weather-instruments-censor" className="ac-input"/>
<label htmlFor="weather-instruments-censor" className="ac-label" >{weatherInstruments}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/wind-speed-direction-indicator/"  className="init-right line bold-600" onClick={()=>handleClick()}>{windSpeedDirectionIndicator}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/anemometers-weather-instruments/"  className="init-right line bold-600" onClick={()=>handleClick()}>{anemometersWeatherInstruments}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/rain-gauge/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rainGauge}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/instrument-screens/"  className="init-right line bold-600" onClick={()=>handleClick()}>{instrumentScreens}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/moisture-meters/" className="init-right line bold-600" onClick={()=>handleClick()}>{moistureMeters}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/soil-ph-meters-salt-meters-co2-meters-otheres/" className="init-right line bold-600" onClick={()=>handleClick()}>{soilPhMetersSaltMetersCo2MetersOtheres}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/refractometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{refractometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/ph-meters/" className="init-right line bold-600" onClick={()=>handleClick()}>{phMeters}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="timers-censor" name="timers-censor" className="ac-input"/>
<label htmlFor="timers-censor" className="ac-label" >{timers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/timers-second-layer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{timersSecondLayer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/stopwatches/"  className="init-right line bold-600" onClick={()=>handleClick()}>{stopwatches}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/sandglasses/"  className="init-right line bold-600" onClick={()=>handleClick()}>{sandglasses}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="optional-probes-censor" name="optional-probes-censor" className="ac-input"/>
<label htmlFor="optional-probes-censor" className="ac-label" >{optionalProbes}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l751/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL751}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l753/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL753}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l754/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL754}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l200TII-Series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL200TIISeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l200th2a-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL200th2aSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l400t-sk-1110-sk-1120-sk-7000PRTII/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL400tSk1110Sk1120Sk7000PRTII}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-1260/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk1260}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-1250MCIII-sk-1250MCIIIa/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk1250MCIIISk1250MCIIIa}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-270wp-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk270wpSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-250wp2-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk250wp2Series}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-100wp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk100wp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-810pt/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk810pt}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-110trh-b/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk110trhB}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-rhc-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkRhcSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-m460-t-sk-m350-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkM460TSkM350T}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-610ph-2/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk610ph2}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-660ph-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk660phSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph2-sk-650ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk620ph2Sk650ph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk620ph}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="charts-and-pens-censor" name="charts-and-pens-censor" className="ac-input"/>
<label htmlFor="charts-and-pens-censor" className="ac-label" >{chartsAndPens}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermohygrographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Thermohygrographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-aurora90-3-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forAurora903Thermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Thermograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-hygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Hygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-barograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Barograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-remote-thermographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2RemoteThermographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-cube-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniCubeThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-star-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniStarThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-alpha-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniAlphaThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-event-recorder/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forEventRecorder}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-lmmc-temperature-recorder/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forLmmcTemperatureRecorder}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-various-recorders/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forVariousRecorders}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/cartridge-pens/"  className="init-right line bold-600" onClick={()=>handleClick()}>{cartridgePens}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/other-accessories/" className="init-right line bold-600" onClick={()=>handleClick()}>{otherAccessories}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/discontinued-products/" className="init-right line bold-600" onClick={()=>handleClick()}>{discontinuedProducts}</LocalizedLink>
</li>
</ul>
);
};

export default CategoryHamburgerMenu;