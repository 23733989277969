import React, {useState} from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";

const CategoryHamburgerMenu = () => {
const {
thermometers,
digitalThermometers,
dataloggersTemperature,
analogThermometers,
thermographs,
remoteSensingDialThermometers,
rsdOrder,
rsdSp,
rsdWl,
rsdFl,
rsdSt,
rsdWp,
wpWl,
wpFl,
wpS,
wpT,
rsdO,
oWl,
oFl,
oS,
oT,
rsdEc,
ecWl,
ecFl,
ecSt,
ecWp,
ecRu,
rsdRc,
rsdDc,
bimetalThermometers,
bmOrder,
bmSp,
bmT,
bmS,
bmF,
bmR,
bmEc,
bmWp,
bmDc,
standardGlassThermometers,
glassThermometers,
thermocouples,
resistanceTemperatureDetectors,
thermohygrometers,
digitalThermohygrometers,
dataloggersTemperatureHumidity,
transmitters,
dialThermohygrometers,
thermohygrographs,
psychrometers,
anemometers,
heatStressMonitors,
infraredThermometers,
barometers,
digitalBarometer,
dialBarometers,
barograph,
recorders,
indicators,
weatherInstruments,
windSpeedDirectionIndicator,
anemometersWeatherInstruments,
rainGauge,
barometersWeatherInstruments,
loggers,
instrumentScreens,
moistureMeters,
soilPhMetersSaltMetersCo2MetersOtheres,
refractometers,
phMeters,
timers,
timersSecondLayer,
stopwatches,
sandglasses,
optionalProbes,
forSkL751,
forSkL753,
forSkL754,
forSkL700rT,
forSkL700rTh,
forSkL200TIISeries,
forSkL200th2aSeries,
forSkL400tSk1110Sk1120Sk7000PRTII,
forSk1260,
forSk1250MCIIISk1250MCIIIa,
forSk270wpSeries,
forSk250wp2Series,
forSk100wp,
forSk810pt,
forSk110trhB,
forSkRhcSeries,
forSkM460TSkM350T,
forSkM350rT,
forSkM350rTrh,
forSk610ph2,
forSk660phSeries,
forSk620ph2Sk650ph,
forSk620ph,
forThermocouple,
chartsAndPens,
forSigma2Thermohygrographs,
forAurora903Thermohygrograph,
forSigma2Thermograph,
forSigma2Hygrograph,
forSigma2Barograph,
forSigma2RemoteThermographs,
forMiniCubeThermohygrograph,
forMiniStarThermohygrograph,
forMiniAlphaThermohygrograph,
forEventRecorder,
forLmmcTemperatureRecorder,
forVariousRecorders,
cartridgePens,
otherAccessories,
seriesProducts,
seriesSk270wp,
seriesSk1260,
seriesSk110trhb,
seriesSk630ph,
seriesSk660ph,
seriesSk960a,
seriesBms90s,
seriesBmt90s,
seriesBmt75s,
seriesBms75p,
seriesBmt75p,
seriesBms100p,
seriesBmt100p,
seriesProtectorPipeForBimetal,
seriesProtectorPipeForRemoteSensing,
seriesLb100s150s,
seriesVb100p,
seriesYoungAnemometer,
seriesHandheldAnemometer,
seriesWvat6d0,
seriesWat6d0,
seriesRa001,
seriesIndicatorTemp,
seriesIndicatorHum,
seriesSkrsd10,
seriesSkrm10c,
seriesSkrm18j,
seriesSurfaceBimetal,
seriesPocketBimetal,
seriesAsphaltBimetal,
seriesMiluno,
seriesMilunotz,
seriesWoodenzBase,
seriesWoodenzBase2,
seriesSoilAir,
seriesCheckermate,
seriesSigma2HvrCharts,
seriesSigmaminiCharts,
seriesSigma2OtherAccessories,
seriesSkrhgOption,
seriesCalibrationSolution,
seriesSensorFilters,
seriesTm24p,
seriesBabymate,
traceabilityProducts,
discontinuedProducts,
} = useTranslationsCat();

const [checked, setChecked] = useState(false)
const handleClick = () => setChecked(!checked)

return(
<ul className="ac-text level-2">
<li className={'ac-product'}>
<input type="checkbox" id="thermometers-censor" name="thermometers-censor" className="ac-input"/>
<label htmlFor="thermometers-censor" className="ac-label" >{thermometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/digital-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/dataloggers-temperature/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dataloggersTemperature}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/analog-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{analogThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/thermographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/remote-sensing-dial-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{remoteSensingDialThermometers}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="rsd-order-censor" name="rsd-order-censor" className="ac-input"/>
<label htmlFor="rsd-order-censor" className="ac-label" >{rsdOrder}</label>
<ul className="ac-text level-3">
<li className={'ac-product'}>
<input type="checkbox" id="rsd-sp-censor" name="rsd-sp-censor" className="ac-input"/>
<label htmlFor="rsd-sp-censor" className="ac-label" >{rsdSp}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-wl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rsdWl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-fl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rsdFl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-st/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rsdSt}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="rsd-wp-censor" name="rsd-wp-censor" className="ac-input"/>
<label htmlFor="rsd-wp-censor" className="ac-label" >{rsdWp}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-wl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{wpWl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-fl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{wpFl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{wpS}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{wpT}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="rsd-o-censor" name="rsd-o-censor" className="ac-input"/>
<label htmlFor="rsd-o-censor" className="ac-label" >{rsdO}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-wl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{oWl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-fl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{oFl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{oS}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{oT}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="rsd-ec-censor" name="rsd-ec-censor" className="ac-input"/>
<label htmlFor="rsd-ec-censor" className="ac-label" >{rsdEc}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-wl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{ecWl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-fl/"  className="init-right line bold-600" onClick={()=>handleClick()}>{ecFl}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-st/"  className="init-right line bold-600" onClick={()=>handleClick()}>{ecSt}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-wp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{ecWp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-ru/"  className="init-right line bold-600" onClick={()=>handleClick()}>{ecRu}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-rc/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rsdRc}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-dc/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rsdDc}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bimetal-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bimetalThermometers}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="bm-order-censor" name="bm-order-censor" className="ac-input"/>
<label htmlFor="bm-order-censor" className="ac-label" >{bmOrder}</label>
<ul className="ac-text level-3">
<li className={'ac-product'}>
<input type="checkbox" id="bm-sp-censor" name="bm-sp-censor" className="ac-input"/>
<label htmlFor="bm-sp-censor" className="ac-label" >{bmSp}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmT}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmS}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-f/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmF}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-r/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmR}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-ec/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmEc}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-wp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmWp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/bm-order/bm-dc/"  className="init-right line bold-600" onClick={()=>handleClick()}>{bmDc}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/standard-glass-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{standardGlassThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/glass-thermometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{glassThermometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/thermocouples/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermocouples}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermometers/resistance-temperature-detectors/"  className="init-right line bold-600" onClick={()=>handleClick()}>{resistanceTemperatureDetectors}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="thermohygrometers-censor" name="thermohygrometers-censor" className="ac-input"/>
<label htmlFor="thermohygrometers-censor" className="ac-label" >{thermohygrometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/digital-thermohygrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalThermohygrometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/dataloggers-temperature-Humidity/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dataloggersTemperatureHumidity}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/transmitters/"  className="init-right line bold-600" onClick={()=>handleClick()}>{transmitters}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/dial-thermohygrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dialThermohygrometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/thermohygrographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{thermohygrographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/thermohygrometers/psychrometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{psychrometers}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/anemometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{anemometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/heat-stress-monitors/" className="init-right line bold-600" onClick={()=>handleClick()}>{heatStressMonitors}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/infrared-thermometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{infraredThermometers}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="barometers-censor" name="barometers-censor" className="ac-input"/>
<label htmlFor="barometers-censor" className="ac-label" >{barometers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/digital-barometer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{digitalBarometer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/dial-barometers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{dialBarometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/barometers/barograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{barograph}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/recorders/" className="init-right line bold-600" onClick={()=>handleClick()}>{recorders}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/indicators/" className="init-right line bold-600" onClick={()=>handleClick()}>{indicators}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="weather-instruments-censor" name="weather-instruments-censor" className="ac-input"/>
<label htmlFor="weather-instruments-censor" className="ac-label" >{weatherInstruments}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/wind-speed-direction-indicator/"  className="init-right line bold-600" onClick={()=>handleClick()}>{windSpeedDirectionIndicator}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/anemometers-weather-instruments/"  className="init-right line bold-600" onClick={()=>handleClick()}>{anemometersWeatherInstruments}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/rain-gauge/"  className="init-right line bold-600" onClick={()=>handleClick()}>{rainGauge}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/barometers-weather-instruments/"  className="init-right line bold-600" onClick={()=>handleClick()}>{barometersWeatherInstruments}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/loggers/"  className="init-right line bold-600" onClick={()=>handleClick()}>{loggers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/weather-instruments/instrument-screens/"  className="init-right line bold-600" onClick={()=>handleClick()}>{instrumentScreens}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/moisture-meters/" className="init-right line bold-600" onClick={()=>handleClick()}>{moistureMeters}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/soil-ph-meters-salt-meters-co2-meters-otheres/" className="init-right line bold-600" onClick={()=>handleClick()}>{soilPhMetersSaltMetersCo2MetersOtheres}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/refractometers/" className="init-right line bold-600" onClick={()=>handleClick()}>{refractometers}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/ph-meters/" className="init-right line bold-600" onClick={()=>handleClick()}>{phMeters}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="timers-censor" name="timers-censor" className="ac-input"/>
<label htmlFor="timers-censor" className="ac-label" >{timers}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/timers-second-layer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{timersSecondLayer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/stopwatches/"  className="init-right line bold-600" onClick={()=>handleClick()}>{stopwatches}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/timers/sandglasses/"  className="init-right line bold-600" onClick={()=>handleClick()}>{sandglasses}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="optional-probes-censor" name="optional-probes-censor" className="ac-input"/>
<label htmlFor="optional-probes-censor" className="ac-label" >{optionalProbes}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l751/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL751}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l753/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL753}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l754/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL754}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL700rT}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-th/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL700rTh}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l200TII-Series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL200TIISeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l200th2a-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL200th2aSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-l400t-sk-1110-sk-1120-sk-7000PRTII/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkL400tSk1110Sk1120Sk7000PRTII}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-1260/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk1260}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-1250MCIII-sk-1250MCIIIa/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk1250MCIIISk1250MCIIIa}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-270wp-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk270wpSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-250wp2-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk250wp2Series}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-100wp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk100wp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-810pt/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk810pt}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-110trh-b/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk110trhB}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-rhc-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkRhcSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-m460-t-sk-m350-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkM460TSkM350T}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-t/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkM350rT}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-trh/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSkM350rTrh}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-610ph-2/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk610ph2}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-660ph-series/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk660phSeries}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph2-sk-650ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk620ph2Sk650ph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSk620ph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/optional-probes/for-thermocouple/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forThermocouple}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="charts-and-pens-censor" name="charts-and-pens-censor" className="ac-input"/>
<label htmlFor="charts-and-pens-censor" className="ac-label" >{chartsAndPens}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermohygrographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Thermohygrographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-aurora90-3-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forAurora903Thermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Thermograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-hygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Hygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-barograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2Barograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-remote-thermographs/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forSigma2RemoteThermographs}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-cube-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniCubeThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-star-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniStarThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-mini-alpha-thermohygrograph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forMiniAlphaThermohygrograph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-event-recorder/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forEventRecorder}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-lmmc-temperature-recorder/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forLmmcTemperatureRecorder}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/for-various-recorders/"  className="init-right line bold-600" onClick={()=>handleClick()}>{forVariousRecorders}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/charts-and-pens/cartridge-pens/"  className="init-right line bold-600" onClick={()=>handleClick()}>{cartridgePens}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/other-accessories/" className="init-right line bold-600" onClick={()=>handleClick()}>{otherAccessories}</LocalizedLink>
</li>
<li className={'ac-product'}>
<input type="checkbox" id="series-products-censor" name="series-products-censor" className="ac-input"/>
<label htmlFor="series-products-censor" className="ac-label" >{seriesProducts}</label>
<ul className="ac-text level-3">
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk270wp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk270wp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk1260/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk1260}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk110trhb/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk110trhb}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk630ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk630ph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk660ph/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk660ph}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sk960a/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSk960a}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bms90s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBms90s}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bmt90s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBmt90s}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bmt75s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBmt75s}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bms75p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBms75p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bmt75p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBmt75p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bms100p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBms100p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-bmt100p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBmt100p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-protector-pipe-for-bimetal/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesProtectorPipeForBimetal}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-protector-pipe-for-remote-sensing/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesProtectorPipeForRemoteSensing}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-lb100s-150s/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesLb100s150s}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-vb100p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesVb100p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-young-anemometer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesYoungAnemometer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-handheld-anemometer/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesHandheldAnemometer}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-wvat6d0/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesWvat6d0}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-wat6d0/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesWat6d0}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-ra001/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesRa001}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-indicator-temp/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesIndicatorTemp}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-indicator-hum/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesIndicatorHum}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-skrsd10/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSkrsd10}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-skrm10c/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSkrm10c}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-skrm18j/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSkrm18j}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-surface-bimetal/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSurfaceBimetal}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-pocket-bimetal/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesPocketBimetal}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-asphalt-bimetal/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesAsphaltBimetal}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-miluno/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesMiluno}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-milunotz/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesMilunotz}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-woodenz-base/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesWoodenzBase}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-woodenz-base2/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesWoodenzBase2}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-soil-air/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSoilAir}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-checkermate/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesCheckermate}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sigma2-hvr-charts/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSigma2HvrCharts}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sigmamini-charts/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSigmaminiCharts}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sigma2-other-accessories/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSigma2OtherAccessories}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-skrhg-option/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSkrhgOption}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-calibration-solution/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesCalibrationSolution}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-sensor-filters/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesSensorFilters}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-tm24p/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesTm24p}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/series-products/series-babymate/"  className="init-right line bold-600" onClick={()=>handleClick()}>{seriesBabymate}</LocalizedLink>
</li>

</ul>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/traceability-products/" className="init-right line bold-600" onClick={()=>handleClick()}>{traceabilityProducts}</LocalizedLink>
</li>
<li className={'ac-link'}>
<LocalizedLink to="/categories/discontinued-products/" className="init-right line bold-600" onClick={()=>handleClick()}>{discontinuedProducts}</LocalizedLink>
</li>
</ul>
);
};

export default CategoryHamburgerMenu;