import React from "react"
import HeaderNav from "./HeaderNav/HeaderNav"

const Navigation = () => {

  return (
    <nav>
        <HeaderNav/>
    </nav>
  )
}

export default Navigation
