import React from "react";
import useTranslations from "../useTranslations";
import LocalizedLink from "../localizedLink";
import { LocaleContext } from "../layout";
import CategoryNav from "../CategoryNav/CategoryNav";

const HeaderNav = () => {
	const {
		productInfo,
		customerSupport,
		recruitment,
		companyInfo,
		companyProfile,
		history,
		office,
		isoCertificate,
		jcssCertificate,
		privacy,
		contact,
		faq,
		catalogDownload,
		softDownload,
		documents,
	} = useTranslations();

	const ActiveStyle = {
		backgroundColor: '#63B1BC',
		color: "#fff",
		width: "auto",
		textAlign: "center",
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	const { locale } = React.useContext(LocaleContext);
	const HeaderJaUrl = () => {
		return (
			<a
				href="https://recruitment.sksato.co.jp/"
				className="init-bottom"
				target={"_blank"}
				rel="noreferrer"
			>
				{recruitment}
			</a>
		);
	};
	const HeaderEnUrl = () => {
		return (
			<LocalizedLink to="/20210726/" className="init-bottom">
				{recruitment}
			</LocalizedLink>
		);
	};
	return (
		<ul className="pc menu flex">
			<li className="menu__multi" id="productNavi">
				<LocalizedLink
					to="/categories/"
					activeStyle={ActiveStyle}
					partiallyActive={ActiveStyle}
					className="init-bottom"
				>
					{productInfo}
				</LocalizedLink>
				<CategoryNav />
			</li>
			<span>|</span>
			<li className="menu__multi" id="companyNavi">
				<LocalizedLink
					to="/company/"
					activeStyle={ActiveStyle}
					partiallyActive={ActiveStyle}
					className="init-bottom"
				>
					{companyInfo}
				</LocalizedLink>
				<ul className="menu__second-level">
					<li>
						<LocalizedLink
							to="/company/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{companyProfile}
						</LocalizedLink>
					</li>
					{pathName.indexOf("/en") !== -1 ? null : (
						<li>
							<LocalizedLink
								to="/company/history/"
								activeStyle={ActiveStyle}
								className="init-right line"
							>
								{history}
							</LocalizedLink>
						</li>
					)}
					<li>
						<LocalizedLink
							to="/company/office/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{office}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/company/iso/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{isoCertificate}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/company/jcss/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{jcssCertificate}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/company/privacy/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{privacy}
						</LocalizedLink>
					</li>
				</ul>
			</li>
			<span>|</span>
			<li className="menu__multi" id="supportNavi">
				<LocalizedLink
					to="/support/"
					activeStyle={ActiveStyle}
					partiallyActive={ActiveStyle}
					className="init-bottom"
				>
					{customerSupport}
				</LocalizedLink>
				<ul className="menu__second-level">
					<li>
						<LocalizedLink
							to="/support/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{contact}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/support/faq/"
							activeStyle={ActiveStyle}
							partiallyActive={ActiveStyle}
							className="init-right line"
						>
							{faq}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/support/catalog-download/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{catalogDownload}
						</LocalizedLink>
					</li>
					<li>
						<LocalizedLink
							to="/support/software-download/"
							activeStyle={ActiveStyle}
							className="init-right line"
						>
							{softDownload}
						</LocalizedLink>
					</li>
					{pathName.indexOf("/en") !== -1 ? null : (
						<li>
							<LocalizedLink
								to="/support/documents/"
								activeStyle={ActiveStyle}
								className="init-right line"
							>
								{documents}
							</LocalizedLink>
						</li>
					)}
				</ul>
			</li>
			<span>|</span>
			<li className="menu__multi">
				{locale === "ja" ? <HeaderJaUrl /> : <HeaderEnUrl />}
			</li>
			<span>|</span>
		</ul>
	);
};

export default HeaderNav;
