import React, {useState, useEffect} from "react";
import LocalizedLink from "../localizedLink";
import useTranslations from "../useTranslations";
import {SearchBarEn, SearchBarJa} from "../SearchBar/SearchBar";
import LanguageBarHamburger from "../LanguageBarHamburger/languageBarHamburger";
import {LocaleContext} from "../layout";
import CategoryHamburgerMenu from "./CategoryHamburgerMenu";
import {Link} from "gatsby";
import { useLocation } from "@reach/router";

const HamburgerMenu = () => {
    const {
        productInfo,
        customerSupport,
        recruitment,
        companyInfo,
        companyProfile,
        history,
        office,
        isoCertificate,
        jcssCertificate,
        privacy,
        contact,
        faq,
        catalogDownload,
        softDownload,
        documents,
    } = useTranslations();

    const ActiveStyle = {
        backgroundColor: '#63B1BC',
        color: '#fff'
    }
    const [checked, setChecked] = useState(false)
    const handleClick = () => setChecked(!checked)

    const updateState = () => {
        setChecked(!checked)
    }

    const { locale } = React.useContext(LocaleContext)

    const location = useLocation();

    useEffect(() => {
        setChecked(false);
    }, [location.pathname])

    return (
        <nav role="navigation">
            <div id="menuToggle">
                <input className={'hamburger-input'} type="checkbox" checked={checked} onClick={()=>handleClick()} onChange={e => {}} />
                <div className="hamburger">
                    <span className="hamburger-icon"></span>
                    <span className="hamburger-icon"></span>
                    <span className="hamburger-icon"></span>
                </div>
                <section id={'menu'} className="container">
                    <div className="ac-container">
                        <div className="ac level-1">
                            <input className="ac-input" id="ac-2" name="ac-2" type="checkbox"/>
                            <label className="ac-label" htmlFor="ac-2">{productInfo}</label>
                            <CategoryHamburgerMenu />
                        </div>
                        <div className="ac level-1">
                            <input className="ac-input" id="ac-1" name="ac-1" type="checkbox"/>
                            <label className="ac-label" htmlFor="ac-1">{companyInfo}</label>
                            <ul className="ac-text level-2">
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/company/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{companyProfile}</LocalizedLink>
                                </li>
                                {
                                    (locale !== 'ja') ?
                                        null:
                                        <li className={'ac-link'}>
                                            <LocalizedLink to={`/company/history/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{history}</LocalizedLink>
                                        </li>
                                }
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/company/office/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{office}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/company/iso/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{isoCertificate}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/company/jcss/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{jcssCertificate}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/company/privacy/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{privacy}</LocalizedLink>
                                </li>
                            </ul>
                        </div>

                        <div className="ac level-1">
                            <input className="ac-input" id="ac-4" name="ac-4" type="checkbox"/>
                            <label className="ac-label" htmlFor="ac-4">{customerSupport}</label>
                            <ul className="ac-text level-2">
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/support/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{contact}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/support/faq/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{faq}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/support/catalog-download/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{catalogDownload}</LocalizedLink>
                                </li>
                                <li className={'ac-link'}>
                                    <LocalizedLink to={`/support/software-download/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{softDownload}</LocalizedLink>
                                </li>
                                {
                                    (locale !== 'ja') ?
                                        null:
                                        <li className={'ac-link'}>
                                            <LocalizedLink to={`/support/documents/`} activeStyle={ActiveStyle} className="init-right line" onClick={()=>handleClick()}>{documents}</LocalizedLink>
                                        </li>
                                }
                            </ul>
                        </div>
                        <div className="ac level-1">
                            <li className={'ac-link'}>
                                <a href="https://recruitment.sksato.co.jp/" className="ac-link recruit">{recruitment}</a>
                            </li>
                        </div>
                        <div className="ac-search">
                            {
                                (locale !== 'ja') ?
                                    <SearchBarEn callback={()=>updateState()}/>:
                                    <SearchBarJa callback={()=>updateState()}/>
                            }
                            <div className="ac-search-child">
                                <LocalizedLink to={`/support/`} activeStyle={ActiveStyle} className="ac-item-contact" onClick={()=>handleClick()}>{contact}</LocalizedLink>
                                <LocalizedLink to={`/support/faq/`} activeStyle={ActiveStyle} className="ac-item-faq" onClick={()=>handleClick()}>{faq}</LocalizedLink>
                                {(locale === 'ja')?
                                    <Link activeStyle={ActiveStyle} className="ac-item-faq" to={"/support/registration"} onClick={()=>handleClick()}>お客様製品登録</Link>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="ac-lang">
                            <LanguageBarHamburger callback={()=>updateState()} />
                        </div>
                    </div>
                </section>
            </div>
        </nav>
    );
}
export default HamburgerMenu;
