import React from "react"
import {MDXProvider} from "@mdx-js/react"
import Navigation from "./navigation"
import MdxLink from "./mdxLink"
import LanguageBar from "./LanguageBar/languageBar"
import Header from "./Header/Header"
import {Helmet} from "react-helmet";

const LocaleContext = React.createContext()
const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale } }) => (

  <LocaleContext.Provider value={{ locale }}>
      <Helmet htmlAttributes={{
          lang: locale,
      }}>
          <meta charSet="utf-8" />
          <title>佐藤計量器製作所</title>
          <meta name="description" content="温度計・湿度計のカタログと通販を行う佐藤計量器製作所のホームページです。" />
          <link rel="canonical" href="https://www.sksato.co.jp/"/>

          {
              (pathName.indexOf('/en')!==-1)?
                  <link rel="alternate" href="https://www.sksato.co.jp/en/" hrefLang="en"/> :
                  <link rel="alternate" href="https://www.sksato.co.jp/" hrefLang="ja"/>
          }
          <meta property="og:type" content="website" />
          <meta property="og:title" content="佐藤計量器製作所" />
          <meta property="og:description" content="温度計・湿度計のカタログと通販を行う佐藤計量器製作所のホームページです。" />
          <meta property="og:url" content="https://www.sksato.co.jp/" />
          <meta property="og:site_name" content="佐藤計量器製作所" />
          <link rel="apple-touch-icon" href="/static/apple-touch-icon.png" />
      </Helmet>
    <div className="global-wrapper">
      <header className="global-header">
          <div className="pc">
              <LanguageBar/>
          </div>
          <Header />
        <Navigation />
      </header>
      <MDXProvider components={{ a: MdxLink }}>
        <main>{children}</main>
      </MDXProvider>
    </div>
  </LocaleContext.Provider>
)

export { Layout, LocaleContext }
