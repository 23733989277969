// Only one item MUST have the "default: true" key

module.exports = {
  ja: {
    default: true,
    path: `ja`,
    locale: `ja-JP`,
    dateFormat: `YYYY年MM月DD日`,
    siteLanguage: `ja`,
    ogLanguage: `ja_JP`,
    defaultTitle: `skSATOオンラインショップ - 温度計・湿度計のカタログと通販`,
    defaultDescription: `skSATOオンラインショップ - 温度計・湿度計のカタログと通販`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `skSATO - ONLINE`,
    defaultDescription: `skSATO - ONLINE`,
  },
}
