import {Link} from "gatsby"
import React from "react"
import Styles from "./languageBarHamburger.module.scss"
import {LocaleContext} from "../layout";

const LanguageBarHamburger = (props) => {
    const { locale } = React.useContext(LocaleContext);

    const clickAction = () => {
       props.callback()
    }

    return (
        <div className={`${Styles.bar}`}>
            <div className={Styles.links}>
                {
                    (locale !== 'ja' ) ?
                        <Link to="/" hrefLang="ja" className={`${Styles.link}`} onClick={()=>clickAction()}>
                            <span className={`${Styles.jpFlag} img-box`}></span>
                            日本語
                        </Link>
                        : <Link to="/en" hrefLang="en" className={Styles.link} onClick={()=>clickAction()}>
                            <span className={`${Styles.usFlag} img-box`}></span>
                            English
                        </Link>
                }
            </div>
        </div>
    )
}

export default LanguageBarHamburger