import CustomLayout from "./wrapPageElement"
import "./src/styles/style.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onInitialClientRender = () => {
    setTimeout(function() {
        document.getElementById("___loader").style.display = "none"
    }, 1000)
}

export const wrapPageElement = CustomLayout
