import React, {Component, useEffect, useState} from "react";
import Styles from "./SearchBar.module.scss";
import Axios from "axios"
import * as JsSearch from "js-search"
import LocalizedLink from "../localizedLink";
import searchIcon from "./searchicon.svg"

class SearchBarJa extends Component{
  state = {
    bookList: [],//JSONの内容を格納
    search: [],//JsSearchインスタンスを格納
    searchResults: [],//検索結果を格納
    searchQuery: "",//検索ワード
  }
  /**
   * 初期化 search.jsonを読み込む
   */
  async componentDidMount() {
    Axios.get("/search-ja.json")
        .then(result => {
          this.setState({ bookList: result.data })
          this.rebuildIndex()
        })
        .catch(err => {
          console.log(err)
        })
  }

  /**
   * 検索方法の設定
   */
  rebuildIndex = () => {
    const { bookList } = this.state;
    // JsSearchインスタンス作成（検索対象のリストでユニークとなるキーを指定する）
    const dataToSearch = new JsSearch.Search("sku");

    // 検索ワードをいい感じに変換する（とりあえずスペースで分割し、複数文字列で検索）
    dataToSearch.tokenizer = {
      tokenize( text ) {
        return text.split(/\s+/i);
      }
    };

    // 部分一致で検索する
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

    // 検索ワードを小文字変換、trimする（記述しなくてもデフォルトで設定されている）
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();

    //検索方法の設定
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("sku");

    // 検索を行うキー
    dataToSearch.addIndex("sku");
    dataToSearch.addIndex("name");
    dataToSearch.addIndex("model");
    dataToSearch.addIndex("shortDescription");

    // 検索対象となるリストを設定
    dataToSearch.addDocuments(bookList);
    this.setState({ search: dataToSearch });
  }

  /**
   * 検索ワード変更時に検索を行う
   */

  searchData = e => {
    const toHalfWidth = function(value) {
      return value
          .replace(/[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
          })
          .replace(/[‐－―ー]/g, "-") // ハイフンなど
          .replace(/[～〜]/g, "~") // チルダ
          .replace(/　/g, " "); // スペース
    };
    const { search } = this.state;
    const queryResult = search.search(toHalfWidth(e.target.value));
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }

  render() {
    const { bookList, searchResults, searchQuery } = this.state;
    const queryResults = searchQuery === "" ? bookList : searchResults;
    queryResults.sort(function (x, y) {
      let a = x.sku,
          b = y.sku;
      return a === b ? 0 : a > b ? 1 : -1;
    });
    const keyDown = (e) => {
      if (e.keyCode === 13) document.getElementById('search-button').click()
    }

    return (
        <div className={`img-box ${Styles.bar}`}>
          <div>
            <div className={Styles.searchField}>
                  <input
                      className={Styles.searchInput}
                      autoComplete="off"
                      type="text"
                      placeholder='検索ワード'
                      value={searchQuery}
                      onChange={this.searchData}
                      onKeyDown={(e) => keyDown(e)}
                  />
              }
              <div className={Styles.searchAction}>
                <LocalizedLink to={'/search-result'} state={{queryResults: queryResults}} id="search-button" className={Styles.searchButton} onClick={this.props.callback}>
                  <span className={Styles.buttonIcon}><img src={searchIcon} alt="search-icon" width="23.995" height="24.867" /></span>
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
class SearchBarEn extends Component{
  state = {
    bookList: [],//JSONの内容を格納
    search: [],//JsSearchインスタンスを格納
    searchResults: [],//検索結果を格納
    searchQuery: "",//検索ワード
  }
  /**
   * 初期化 search.jsonを読み込む
   */
  async componentDidMount() {
    Axios.get("/search-en.json")
        .then(result => {
          this.setState({ bookList: result.data })
          this.rebuildIndex()
        })
        .catch(err => {
          console.log(err)
        })
  }

  /**
   * 検索方法の設定
   */
  rebuildIndex = () => {
    const { bookList } = this.state;
    // JsSearchインスタンス作成（検索対象のリストでユニークとなるキーを指定する）
    const dataToSearch = new JsSearch.Search("sku");

    // 検索ワードをいい感じに変換する（とりあえずスペースで分割し、複数文字列で検索）
    dataToSearch.tokenizer = {
      tokenize( text ) {
        return text.split(/\s+/i);
      }
    };

    // 部分一致で検索する
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

    // 検索ワードを小文字変換、trimする（記述しなくてもデフォルトで設定されている）
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();

    //検索方法の設定
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("sku");

    // 検索を行うキー
    dataToSearch.addIndex("sku");
    dataToSearch.addIndex("name");
    dataToSearch.addIndex("model");
    dataToSearch.addIndex("shortDescription");

    // 検索対象となるリストを設定
    dataToSearch.addDocuments(bookList);
    this.setState({ search: dataToSearch });
  }

  /**
   * 検索ワード変更時に検索を行う
   */
  searchData = e => {
    const toHalfWidth = function(value) {
      return value
          .replace(/[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
          })
          .replace(/[‐－―ー]/g, "-") // ハイフンなど
          .replace(/[～〜]/g, "~") // チルダ
          .replace(/　/g, " "); // スペース
    };
    const { search } = this.state;
    const queryResult = search.search(toHalfWidth(e.target.value));
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }

  render() {
    const { bookList, searchResults, searchQuery } = this.state;
    const queryResults = searchQuery === "" ? bookList : searchResults;
    queryResults.sort(function (x, y) {
      let a = x.sku,
          b = y.sku;
      return a === b ? 0 : a > b ? 1 : -1;
    });
    const keyDown = (e) => {
      if (e.keyCode === 13) document.getElementById('search-button').click()
    }

    return (
        <div className={`img-box ${Styles.bar}`}>
          <div>
            <div className={Styles.searchField}>
              <input
                  id="search-input"
                  className={Styles.searchInput}
                  autoComplete="off"
                  type="text"
                  placeholder='search...'
                  value={searchQuery}
                  onChange={this.searchData}
                  onKeyDown={(e) => keyDown(e)}
              />
              <div className={Styles.searchAction}>
                <LocalizedLink to={'/search-result'} state={{queryResults: queryResults}} id="search-button" className={Styles.searchButton} onClick={this.props.callback}>
                  <span className={Styles.buttonIcon}><img src={searchIcon} alt="search-icon" width="23.995" height="24.867"/></span>
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export { SearchBarJa, SearchBarEn };
