import React from "react";
import LocalizedLink from "../localizedLink";
import useTranslations from "../useTranslations";
import Styles from "./Header.module.scss";
import {SearchBarEn, SearchBarJa} from "../SearchBar/SearchBar";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import {LocaleContext} from "../layout";

const Header = () => {
  const { faq, contact } = useTranslations();
  const { locale } = React.useContext(LocaleContext)
  return (
    <div>
      <div className={` pc ${Styles.header}`}>
        <LocalizedLink
          to={"/"}
          className={`${Styles.logo} img-box`}
        ></LocalizedLink>
        {
          (locale === 'ja')?
              <SearchBarJa/>:
              <SearchBarEn/>

        }
        <div className={`flex between align-center ${Styles.links} `}>
          <LocalizedLink className="line" to="/support/faq/" aria-label={faq}>
            {faq}
          </LocalizedLink>
          <LocalizedLink className="line" to="/support/" aria-label={contact}>
            {contact}
          </LocalizedLink>
          {locale === 'ja' &&
          <a href="https://shop.sksato.co.jp/" className={`${Styles.toShop} line`} target="_blank" rel="noreferrer">
            ONLINE SHOP <FontAwesomeIcon icon={faShoppingCart} />
          </a>
          }
        </div>
      </div>
      <div className="sp  flex between align-center">
        <HamburgerMenu />
        {locale === 'ja' &&
          <a href="https://shop.sksato.co.jp/" className={`${Styles.toShop} line`}>
            ONLINE SHOP <FontAwesomeIcon icon={faShoppingCart}/>
          </a>
        }
        <LocalizedLink
          to={"/"}
          className={`${Styles.logo} img-box`}
        ></LocalizedLink>
      </div>
    </div>
  );
};

export default Header;
